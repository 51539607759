import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useStaticQuery, graphql } from 'gatsby'
import Helmet from 'react-helmet'
import HeaderSEO from '../components/partials/HeaderSEO'
import $ from 'jquery'

import Layout from '../components/Layout'
import Spacing from '../components/partials/Spacing'
import AboveFooterSection from '../components/AboveFooterSection'

import loader from '../resources/images/spinner.gif'
import ModalImage from 'react-modal-image'
import box01 from '../resources/images/box_page/box01.jpeg'

import { StaticImage } from 'gatsby-plugin-image'

import { getClientId } from '../helpers/getClientId'
import ZoominfoFields from '../components/partials/Zoominfo-fields'
import callZoominfo from '../helpers/zoomInfo'
import { getCookieByName } from '../helpers/getCookieByName'
import { initializeVideos } from '../helpers/initializeVideo'

declare const window: any

interface StateProps {
  loadingData: loadingState
  copyToClipboard: CopyToClipboardState
}
interface loadingState {
  loading: boolean
  lambdaURL: string
}
interface CopyToClipboardState {
  copyToClipboardText: string
}

interface ResponseData {
  identifier: string
  downloadURL: string
}

const box: React.FC<StateProps> = () => {
  const data = useStaticQuery(graphql`
    query BoxSEO {
      allContentfulPages(
        filter: { pageReference: { pageIdentifier: { eq: "Box" } } }
      ) {
        edges {
          node {
            title {
              title
            }
            keywords {
              keywords
            }
            description {
              description
            }
            oGimage {
              fluid(quality: 100) {
                src
              }
            }
          }
        }
      }
    }
  `)

  const [loadingData, setLoadingData] = useState<loadingState>({
    loading: false,
    lambdaURL: '',
  })
  const { loading, lambdaURL } = loadingData

  const [copyToClipboard, setCopyToClipboard] = useState<CopyToClipboardState>({
    copyToClipboardText: 'Copy To Clipboard',
  })
  const { copyToClipboardText } = copyToClipboard

  useEffect(() => {
    // Defer Vimeo Videos
    initializeVideos()
    // Zoominfo Snippet
    callZoominfo('uoOiaHNN8N3QdXczOrJm')

    //  Smooth Scrolling for each step
    $('.btn').on('click', function (event) {
      if (this.hash !== '') {
        event.preventDefault()
        const hash = this.hash
        $('html,body').animate(
          {
            scrollTop: $(hash).offset().top,
          },
          650
        )
      }
    })

    if (sessionStorage.getItem('DownloadedKey')) {
      document.querySelector<HTMLInputElement>('#step2').scrollIntoView()
    }
    if (location.href.includes('https://www.dev.lenses.io/')) {
      setLoadingData({
        ...loadingData,
        lambdaURL:
          'https://384in5y2mk.execute-api.eu-west-1.amazonaws.com/dev/api/v1/leads',
      })
    } else {
      setLoadingData({
        ...loadingData,
        lambdaURL:
          'https://s1qhfrb27e.execute-api.eu-west-1.amazonaws.com/prod/api/v1/leads',
      })
    }

    // Getting clientId
    const interval = setInterval(function () {
      if (getClientId()) {
        let gaClientId = getClientId()
        document.querySelector<HTMLInputElement>('#ga_client_id').value =
          gaClientId
        clearInterval(interval)
      }
    }, 1000)
    // es-lint-disable-next-line
  }, [])

  const pageSEO = data.allContentfulPages.edges[0].node

  const handleFormSubmit = (e) => {
    e.preventDefault()
    document.querySelector<HTMLInputElement>('#splunk_id').value =
      getCookieByName('mintjs%3Auuid')
    document.querySelector<HTMLInputElement>('#timestamp').value =
      new Date().toLocaleString()
    if (
      document.querySelector<HTMLInputElement>('#terms_of_use').checked &&
      document.querySelector<HTMLInputElement>('#email').value !== ''
    ) {
      document.querySelector<HTMLInputElement>('#formsubmit').disabled = true
      sessionStorage.setItem('DownloadedKey', 'true')
      setLoadingData({
        ...loadingData,
        loading: true,
      })
      let emailValue = document.querySelector<HTMLInputElement>('#email').value
      axios
        .post(`${lambdaURL}`, {
          email: emailValue,
          source: 'website',
          company: 'Not provided',
          license: 'box',
        })
        .then(function (res) {
          const data = res.data as ResponseData
          sessionStorage.setItem('id', data.identifier)
          document.querySelector<HTMLInputElement>('#license').value =
            data.identifier
          document.querySelector<HTMLInputElement>('#downloadURL').value =
            data.downloadURL
          document
            .querySelector<HTMLFormElement>('#zoominfo-all-fields-form')
            .submit()
        })
        .catch(function (err) {
          console.log(err)
          window.location.href = '/backend-error/'
        })
    }
  }

  const Scroll = () => {
    if (document.querySelector('#email')) {
      document.querySelector<HTMLInputElement>('#email').focus()
    }
  }

  const handleCopy = (id) => {
    var r = document.createRange()
    r.selectNode(document.getElementById(id))
    window.getSelection().removeAllRanges()
    window.getSelection().addRange(r)
    document.execCommand('copy')
    window.getSelection().removeAllRanges()

    setCopyToClipboard({
      copyToClipboardText: 'Copied !',
    })
  }

  return (
    <Layout>
      <HeaderSEO pageSEO={pageSEO} />
      <Helmet>
        <link rel="canonical" href="https://lenses.io/apache-kafka-docker/" />
      </Helmet>
      {loading && (
        <section className="pt-5 " style={{ marginTop: '250px' }}>
          <div className="text-center primary-text fw-900 f-20">
            Please wait...
          </div>
          <div className="text-center pb-5 justify-content my-5">
            <img
              className=" pb-5"
              src={loader}
              alt="spinner"
              style={{ marginBottom: '1000px' }}
            />
          </div>
        </section>
      )}
      <StaticImage
        className="home-fixed-top"
        placeholder="blurred"
        src="../resources/images/homepage/hero_lens.svg"
        alt="Lenses DataOps docker box"
      />
      <section className="mt-5 pt-5">
        <div className="container-1">
          <div className="d-flex flex-wrap mt-1 pb-3">
            <div className="col-md-6 col-12 pt-5">
              <h1 className="pb-3 pr-5">
                Learn DataOps in the Lenses Kafka Docker Box
              </h1>
              <div className="pr-3">
                <p className="hero-description-dark pr-5 f-22 lh-35">
                  Lenses Box is a complete container solution for you to build
                  applications on a localhost Apache Kafka docker.
                  <br />
                  <br />
                  <span id="enjoy">Enjoy a 3-min tour!</span>
                </p>
              </div>
              <div>
                <div className="roll-button">
                  <a href="#step1" className="bg-red text-white mt-1 mr-2">
                    <span data-hover="Start">Start</span>
                    <b>
                      <i
                        className="fa fa-angle-down ml-2"
                        aria-hidden="true"
                      ></i>
                    </b>
                  </a>
                </div>
                <div className="pr-3 mt-4 mobile-padding-reset">
                  {/* ATTENTION! Form ID needs to be matching Zoominfo settings as described here https://landoop.atlassian.net/wiki/spaces/MAR/pages/2910453761/Zoominfo+Website+integration */}
                  <form
                    id="zoominfo-all-fields-form"
                    onSubmit={(e) => handleFormSubmit(e)}
                    className="d-flex align-items-center mb-3 form-homepage"
                    action="https://go.pardot.com/l/877242/2021-05-13/5nrxcq"
                    method="POST"
                    acceptCharset="UTF-8"
                  >
                    <ZoominfoFields
                      includeCompany={true}
                      includePhone={true}
                      includeJobTitle={true}
                    />
                    <input
                      name="Email"
                      autoComplete="email"
                      className="border-0 f-13 ml-2 w-100 py-1 email-form-home"
                      id="email"
                      placeholder="Email"
                      required
                    />
                    {/* License */}
                    <input name="License" id="license" className="d-none" />
                    <input
                      name="downloadURL"
                      id="downloadURL"
                      className="d-none"
                    />

                    <input name="Timestamp" id="timestamp" className="d-none" />

                    <input
                      name="GA Client ID"
                      type="hidden"
                      className="d-none"
                      id="ga_client_id"
                    />

                    <input
                      type="hidden"
                      name="Splunk ID"
                      id="splunk_id"
                      className="d-none"
                    />

                    <div className="m-2 terms-checkbox-box mr-4">
                      <label className="d-none" htmlFor="Terms Of Use">
                        {' '}
                        Terms of use and Privacy Policy
                      </label>
                      <input
                        type="checkbox"
                        name="Terms Of Use"
                        id="terms_of_use"
                        required
                      />{' '}
                      <span className="pl-1">
                        By submitting the form, you agree to our{' '}
                        <a
                          className="link-text"
                          href="/legals/terms-and-conditions/"
                        >
                          Terms of Use{' '}
                        </a>
                        and{' '}
                        <a className="link-text" href="/legals/policy/">
                          Privacy Policy
                        </a>
                      </span>
                    </div>

                    <input
                      className="bg-light-blue text-white form-homepage-button f-14 mr-1 pt-1 px-3 w-75 fw-700 text-center cursor-pointer border-0"
                      id="formsubmit"
                      type="submit"
                      value=" Send Key"
                    />
                  </form>
                </div>

                <div className="col-6 pl-2 pt-5 pb-2 mt-5 z-index-1">
                  <img
                    src="https://img.shields.io/docker/pulls/landoop/lenses.svg"
                    alt="fast data docker"
                  />
                </div>
              </div>
            </div>

            <div className="col-md-6 pt-5 mt-5">
              <div className="mx-auto">
                <div className="cloud-aws-msk-video">
                  <iframe
                    src=""
                    data-src="//player.vimeo.com/video/624320966"
                    style={{
                      position: 'absolute',
                      top: '0',
                      left: '0',
                      width: '100%',
                      height: '100%',
                    }}
                    frameBorder="0"
                    allow="autoplay; fullscreen"
                    allowFullScreen
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className="p-5 mb-4 mobile-padding-reset mobile-no-overflow"
        id="step1"
      >
        <div className="container-1 py-5 mobile-padding-reset">
          <div className="d-flex flex-wrap py-5 mobile-padding-reset align-items-center">
            <div className="col-md-7 pt-2 col-12">
              <p className="box-title-number  mobile-margin-top">01.</p>
              <h2 className="pt-2">What is included</h2>
              <div className="pr-3">
                <p
                  className="hero-description-dark pr-5 mobile-padding-reset mobile-margin-top"
                  style={{ lineHeight: '1.35' }}
                >
                  Apache Kafka <small>v. 2.5.1</small>, Elasticsearch{' '}
                  <small>v. 6.8.7</small> and ecosystem of open source kafka
                  tools and real-time example data.
                  <br />
                  <br />
                  Here are some of the things you’ll be able to do:
                </p>

                <div
                  className="hero-description-dark pt-0 mobile-padding-reset mobile-margin-top"
                  style={{ lineHeight: '1.35' }}
                >
                  <i className="fa fa-check box-green-check pr-2" />
                  Use the real-time data & application catalog
                  <br />
                  <i className="fa fa-check box-green-check pr-2" />
                  Develop real-time applications
                  <br />
                  <i className="fa fa-check box-green-check pr-2" />
                  Monitor Kafka and app flows
                  <br />
                  <i className="fa fa-check box-green-check pr-2" />
                  Run admin Kafka operations
                  <br />
                  <i className="fa fa-check box-green-check pr-2" />
                  Explore streaming data with SQL
                  <br />
                  <i className="fa fa-check box-green-check pr-2" />
                  Protect <span className="code-text"> HIPAA,GDPR,PCI </span>
                  data
                  <br />
                  <i className="fa fa-check box-green-check pr-2" />
                  Use streaming SQL applications
                  <br />
                  <i className="fa fa-check box-green-check pr-2" />
                  Manage schemas, consumers and more
                  <br />
                </div>
              </div>
            </div>

            <div className="col-md-5 pt-5 col-12">
              <div className="pt-5">
                <ModalImage
                  className="img-fluid mx-auto d-block"
                  small={box01}
                  large={box01}
                  alt="understand lenses box"
                />
              </div>
            </div>

            <div className="col-md-12 col-12 text-center">
              <a title="step2" className="btn" href="#step2">
                <i className="fa fa-angle-down f-40" aria-hidden="true"></i>
              </a>
            </div>
          </div>
        </div>
      </section>

      <section
        className="bg-light p-5 mobile-padding-reset mobile-no-overflow"
        id="step2"
      >
        <div className="container-1 mobile-padding-reset">
          <div className="d-flex flex-wrap py-5 mobile-padding-reset">
            <div className="col-md-12 pt-0 col-12">
              <p className="box-title-number mobile-margin-top">02.</p>
              <h2 className="pt-2">Start</h2>
              {typeof window !== 'undefined' &&
              sessionStorage.getItem('DownloadedKey') ? (
                <div className="pr-3 mobile-padding-reset">
                  <p>
                    Looks like you have already received the key (in your
                    inbox). In case you dind&apos;t receive it click
                    <a className="cursor-pointer" onClick={Scroll}>
                      <span className="primary-text"> here</span>
                    </a>{' '}
                  </p>
                </div>
              ) : (
                <a className="cursor-pointer" onClick={Scroll}>
                  <span className="primary-text">First get your key here</span>
                </a>
              )}
              <div>
                <div className="terminal">
                  <p id="command_to_copy" className="pt-2">
                    docker run -e ADV_HOST=127.0.0.1 \<br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-e
                    EULA=&quot;https://licenses.lenses.io/d/?id=
                    {typeof window !== 'undefined' &&
                    sessionStorage.getItem('id')
                      ? sessionStorage.getItem('id')
                      : 'REGISTER_FOR_KEY'}
                    &quot; \
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;--rm -p 3030:3030
                    -p 9092:9092 lensesio/box:latest
                  </p>
                  <button
                    className="box-copy-to-clipboard text-white mt-1"
                    onClick={() => handleCopy('command_to_copy')}
                  >
                    {copyToClipboardText}
                  </button>
                </div>
                <br />
                <b>Note:</b> Make sure you{' '}
                <a
                  href="https://lenses.io/downloads/lenses/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  receive in your email your <u>free access key</u>
                </a>
                <br />
                <b>Note:</b> Kafka Docker will require 4GB RAM. See how to{' '}
                <a
                  href="https://docs.lenses.io/4.3/tools/box/faq/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  increase docker RAM
                </a>
                <div className="mt-5 d-none d-md-block">
                  <span className="f-12">
                    <p>
                      <a
                        href="#!"
                        className="tooltip-right p-text"
                        style={{ width: '200px!important' }}
                        data-tooltip="If you run on a VM and want Kafka to be accessible over the Internet, set as ADV_HOST your public IP address."
                      >
                        <i className="fa fa-lightbulb-o" aria-hidden="true" />
                        <b> TIP </b>
                      </a>
                    </p>
                  </span>
                </div>
                <div className="mt-1 d-md-none d-lg-none d-xl-none">
                  <p>
                    <br />
                    <i className="fa fa-lightbulb-o"></i>
                    <b> TIP </b>
                    <br />
                    If you run on a VM and want Kafka to be accessible over the
                    Internet, set as ADV_HOST your public IP address.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-12 text-center col-12 p-0">
              <a title="step3" className="btn" href="#step3">
                <i className="fa fa-angle-down f-40" aria-hidden="true"></i>
              </a>
            </div>
          </div>
        </div>
      </section>

      <section
        className="p-3 mobile-padding-reset mobile-no-overflow"
        id="step3"
      >
        <div className="container-1 py-5 mobile-padding-reset">
          <div className="d-flex flex-wrap py-5 mobile-padding-reset align-items-center">
            <div className="col-md-6 pt-2 col-12">
              <p className="box-title-number  mobile-margin-top">03.</p>
              <h2 className="pt-2">Access from your browser</h2>
              <div className="pr-3 mobile-padding-reset">
                <div className="hero-description-dark pr-5 mobile-padding-reset  mobile-margin-top">
                  <span className="code-text">http://localhost:3030</span>
                  <br />
                  <br />
                  Default username and password is <br />
                  <b>admin / admin</b>
                </div>
                <br />
              </div>
            </div>

            <div className="col-md-6 pt-5 col-12">
              <div className="pt-5">
                <StaticImage
                  className="img-fluid mx-auto d-block"
                  src="../resources/images/vectors/undraw_authentication_fsn5.svg"
                  placeholder="tracedSVG"
                  alt="lenses user interface from browser"
                />
              </div>
            </div>

            <div className="col-md-12 col-12 text-center">
              <a title="step4" className="btn" href="#step4">
                <i className="fa fa-angle-down f-40" aria-hidden="true"></i>
              </a>
            </div>
          </div>
        </div>
      </section>

      <section
        className="bg-light p-3 mobile-padding-reset mobile-no-overflow"
        id="step4"
      >
        <div className="container-1 py-5 mobile-padding-reset">
          <div className="d-flex flex-wrap p-y5 mobile-padding-reset">
            <div className="col-md-6 pt-3 col-12">
              <p className="box-title-number mobile-margin-top">04.</p>
              <h2 className="pt-2">Explore real-time data</h2>
              <div className="pr-3 mobile-padding-reset">
                <div
                  className="hero-description-dark pr-5 mobile-padding-reset pb-3 mobile-margin-top"
                  style={{ lineHeight: '1.5' }}
                >
                  1. Select{' '}
                  <span className="code-text">sea_vessel_position_reports</span>
                  <br />
                  2. Run the following query:
                </div>
                <div className="sqlterminal">
                  <p>
                    SELECT Speed, Latitude, Longitude
                    <br />
                    FROM sea_vessel_position_reports
                    <br />
                    WHERE Speed &gt; 10
                  </p>
                </div>
                <p className="pt-4">
                  <a
                    className="p-text"
                    href="https://docs.lenses.io/4.3/sql/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="pr-2 fa fa-book" aria-hidden="true"></i>
                    Learn real-time SQL
                  </a>
                </p>
              </div>
            </div>

            <div className="col-md-6 pt-5 col-12">
              <div className="pt-3">
                <StaticImage
                  className="img-fluid mx-auto d-block shadow"
                  src="../resources/images/tour/lenses-sql-studio.png"
                  placeholder="tracedSVG"
                  alt="lenses sql studio"
                />
              </div>
            </div>

            <div className="col-md-12 col-12 text-center">
              <a title="step5" className="btn" href="#step5">
                <i className="fa fa-angle-down f-40" aria-hidden="true"></i>
              </a>
            </div>
          </div>
        </div>
      </section>

      <section
        className="p-2 mobile-padding-reset mobile-no-overflow"
        id="step5"
      >
        <div className="container-1 py-2 mobile-padding-reset">
          <div className="d-flex flex-wrap py-5 mobile-padding-reset">
            <div className="col-md-6 pt-5 col-12">
              <p className="box-title-number mobile-margin-top">05.</p>
              <h2 className="pt-2">Create a new Kafka topic</h2>
              <div className="pr-3 mobile-padding-reset">
                <div
                  className="hero-description-dark pr-5 mobile-padding-reset mobile-margin-top"
                  style={{ lineHeight: '1.5' }}
                >
                  1. Explore data
                  <br />
                  2. Use the <span className="code-text"> New Topic </span>{' '}
                  button
                  <br />
                </div>
                <div className="mt-2">
                  <span className="f-12 pr-5">
                    <p className="d-none d-md-block">
                      <a
                        href="#!"
                        className="tooltip-right p-text"
                        data-tooltip="You can also automate via GitOps"
                      >
                        <i className="fa fa-lightbulb-o" aria-hidden="true" />
                        <b> TIP </b>
                      </a>
                    </p>
                    <p className="d-md-none d-lg-none d-xl-none text-center">
                      <i className="fa fa-lightbulb-o" aria-hidden="true" />
                      <b> TIP </b>
                      <br />
                      You can also automate via GitOps
                    </p>
                  </span>
                </div>
              </div>
            </div>

            <div className="col-md-6 pt-5 col-12">
              <div className="pt-5">
                <StaticImage
                  className="img-fluid mx-auto d-block shadow"
                  src="../resources/images/tour/create-topic.png"
                  placeholder="tracedSVG"
                  alt="create new kafka topic"
                />
              </div>
            </div>

            <div className="col-md-12 text-center col-12">
              <a title="step6" className="btn" href="#step6">
                <i className="fa fa-angle-down f-40" aria-hidden="true" />
              </a>
            </div>
          </div>
        </div>
      </section>

      <section
        className="bg-light p-5 mobile-padding-reset mobile-no-overflow"
        id="step6"
      >
        <div className="container-1 py-5 mobile-padding-reset">
          <div className="d-flex flex-wrap p-y5 mobile-padding-reset">
            <div className="col-md-12 pt-3 col-12">
              <p className="box-title-number mobile-margin-top">06.</p>
              <h2 className="pt-2">Create a data pipeline</h2>
              <div className="pr-3 mobile-padding-reset">
                <div
                  className="hero-description-dark pr-5 mb-0 mobile-padding-reset mobile-margin-top"
                  style={{ lineHeight: '1.35' }}
                >
                  Stream real-time events into the Kafka topic{' '}
                  <small>(using Kafka Connect)</small>
                  <br />
                  <br />
                  1. Navigate to Connectors
                  <br />
                  2. Click <span className="code-text">New Connector </span>
                  and select the file source connector
                  <br />
                  3. Use the following configuration
                </div>
                <br />
                <div className="sqlterminal" style={{ margin: '0' }}>
                  <p>
                    name=my-real-time-file-connector
                    <br />
                    connector.class=org.apache.kafka.connect.file.FileStreamSourceConnector
                    <br />
                    topic=broker_logs
                    <br />
                    file=/var/log/broker.log
                    <br />
                    tasks.max=1
                  </p>
                </div>
                <br />
                <p className="hero-description-dark pr-5 pt-0 mt-0 mobile-padding-reset">
                  <small>
                    This will continuously move log events from
                    /var/log/broker.log into the kafka topic.
                  </small>
                </p>

                <p className="d-none d-md-block">
                  <a
                    href="#!"
                    className="tooltip-right p-text"
                    data-tooltip="To produce messages with a Kafka producer use as bootstrap broker your ADV_HOST and port 9092. eg. PLAINTEXT://127.0.0.1:9092"
                  >
                    <i className="fa fa-lightbulb-o" aria-hidden="true" />
                    <b> KAFKA PRODUCER </b>
                  </a>
                  {/*
                    <a
                      className="ml-3 tooltip-right p-text "
                      data-tooltip="You can produce data with SQL using INSERT INTO statement"
                    >
                      <i className="fa fa-lightbulb-o" aria-hidden="true"></i>
                      <b> USE SQL</b>
                    </a>
                    <a
                      className="ml-3 tooltip-right p-text "
                      data-tooltip="You can add any Kafka Connector plugin to Lenses Box"
                    >
                      <i className="fa fa-lightbulb-o" aria-hidden="true"></i>
                      <b> CONNECTORS</b>
                    </a>
                    */}
                </p>
                <span>
                  <p className=" d-md-none d-lg-none d-xl-none text-center">
                    <i className="fa fa-lightbulb-o"></i>
                    <b> KAFKA PRODUCER </b>
                    <br />
                    To produce messages with a Kafka producer use as bootstrap
                    broker your ADV_HOST and port 9092. eg.
                    PLAINTEXT://127.0.0.1:9092
                  </p>
                  {/*
                     KAFKA PRODUCER
                      <i className="fa fa-lightbulb-o"></i>
                      <b> USE SQL</b>
                      <br />
                      You can produce data with SQL using INSERT INTO statement
                    </p>

                    <p className=" d-md-none d-lg-none d-xl-none text-center">
                      <i className="fa fa-lightbulb-o"></i>
                      <b> USE CODE</b>
                      <br />
                      To produce data from your code just set the bootstrap
                      broker to the host of the Lenses Box and port 9092. Ensure
                      you set the correct ADV_HOST in Docker
                    </p>

                    <p className=" d-md-none d-lg-none d-xl-none text-center">
                      <i className="fa fa-lightbulb-o"></i>
                      <b> CONNECTORS</b>
                      <br />
                      You can add any Kafka Connector plugin to Lenses Box
                    </p>
                    */}
                </span>
              </div>
            </div>

            <div className="col-md-12 col-12 text-center">
              <a title="step7" className="btn" href="#step7">
                <i className="fa fa-angle-down f-40" aria-hidden="true" />
              </a>
            </div>
          </div>
        </div>
      </section>

      <section
        className="p-5 mobile-padding-reset mobile-no-overflow"
        id="step7"
      >
        <div className="container-1 py-5 mobile-padding-reset">
          <div className="d-flex flex-wrap mobile-padding-reset pb-0">
            <div className="col-md-6 pt-3 col-12">
              <p className="pt-5 box-title-number mobile-margin-top">07.</p>
              <h2 className="pt-2">Stream processing</h2>
            </div>
            <div className="col-md-7 col-12">
              <p className="hero-description-dark pr-5 mb-3 mobile-padding-reset mobile-margin-top">
                Use streaming SQL to continuously process real-time data.
              </p>
            </div>
            <div className="col-md-6 col-12">
              <div className="pr-5 mobile-padding-reset">
                <div
                  className="hero-description-dark pr-5 mb-0 pt-0 mobile-padding-reset"
                  style={{ lineHeight: '1.5' }}
                >
                  1. Click <span className="code-text"> SQL Processors </span>
                  and then <span className="code-text"> New </span>
                  <br />
                  2. Set a name and use streaming SQL
                </div>
                <div className="sqlterminal">
                  <p>
                    SET defaults.topic.autocreate=true;
                    <br />
                    INSERT INTO speed_boats
                    <br />
                    &nbsp;&nbsp;SELECT STREAM MMSI, Speed, Longitude AS Long,
                    Latitude AS Lat, `Timestamp`
                    <br />
                    &nbsp;&nbsp;FROM sea_vessel_position_reports
                    <br />
                    &nbsp;&nbsp;WHERE Speed &gt; 10;
                  </p>
                </div>
              </div>

              <div className="hero-description-dark pr-5 pt-3 mb-0 mobile-padding-reset">
                This will continuously filter all messages from the source topic
                into <span className="code-text"> speed_boats </span>
                topic{' '}
                <small> (when the Speed of a boat is greater than 10.</small>
              </div>
              <p className="mb-0 d-none pt-2 d-md-block">
                <a
                  href="#!"
                  className="tooltip-right p-text"
                  data-tooltip="For production we recommend using Kubernetes or Kafka Connect for scaling and fault tolerance"
                >
                  <i className="fa fa-lightbulb-o" aria-hidden="true"></i>
                  <b> TIP</b>
                </a>
              </p>
              <p className="mb-0 d-md-none d-lg-none d-xl-none text-center">
                <i className="fa fa-lightbulb-o" aria-hidden="true"></i>
                <b> TIP</b>
              </p>
            </div>

            <div className="col-md-6 col-12">
              <div className="mobile-margin-top">
                <StaticImage
                  className="img-fluid mx-auto d-block shadow "
                  src="../resources/images/tour/create-processor.png"
                  placeholder="tracedSVG"
                  alt="create-processor"
                />
                <p className="pt-4 pull-right">
                  <a
                    className="p-text"
                    href="https://help.lenses.io/sql/streaming/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fa fa-book pr-2" aria-hidden="true"></i>
                    Learn streaming SQL →
                  </a>
                </p>
              </div>
            </div>

            <div className="col-md-12 text-center col-12">
              <a title="step8" className="btn" href="#step8">
                <i className="fa fa-angle-down f-40" aria-hidden="true"></i>
              </a>
            </div>
          </div>
        </div>
      </section>

      <section
        className="bg-light p-5 mobile-padding-reset mobile-no-overflow"
        id="step8"
      >
        <div className="container-1 py-5 mobile-padding-reset">
          <div className="d-flex flex-wrap py-5 mobile-padding-reset">
            <div className="col-md-6 pt-3 col-12">
              <p className="box-title-number  mobile-margin-top">08.</p>
              <h2 className="pt-2">Consumer Monitor</h2>
              <div className="pr-3 mobile-padding-reset">
                <div
                  className="hero-description-dark pr-5 mobile-padding-reset"
                  style={{ lineHeight: '1.35' }}
                >
                  Now with streaming applications running, access the
                  <span className="code-text"> Consumers </span>
                  link to monitor the status and consumer lag of your real-time
                  apps.
                </div>
                <b>Note:</b> You can also set up alerts to
                <br />
                <StaticImage
                  className="logo-image-alerting mr-3 bg-white mt-2"
                  src="../resources/images/logos-integrations/slack.png"
                  placeholder="tracedSVG"
                  alt="DataOps alerts with Slack"
                />
                <StaticImage
                  className="logo-image-alerting mr-3 bg-white mt-2"
                  src="../resources/images/logos-integrations/microsoft-teams.png"
                  placeholder="tracedSVG"
                  alt="DataOps alerts with Microsoft teams"
                />
                <StaticImage
                  className="logo-image-alerting mr-3 bg-white mt-2"
                  src="../resources/images/homepage/personas/logos/pd_Developer.svg"
                  placeholder="tracedSVG"
                  alt="DataOps alerts with PagerDuty"
                />
                <StaticImage
                  className="logo-image-alerting mr-3 bg-white mt-2"
                  src="../resources/images/homepage/personas/logos/datadog_Developer.svg"
                  placeholder="tracedSVG"
                  alt="DataOps alerts with Datadog"
                />
                <StaticImage
                  className="logo-image-alerting mr-3 bg-white mt-2"
                  src="../resources/images/logos-integrations/prometheus.png"
                  placeholder="tracedSVG"
                  alt="DataOps alerts with Prometheus"
                />
                <br />
                <br />
                <p className="tooltip-right p-text d-none d-md-block">
                  <a
                    href="#!"
                    className="tooltip-right p-text"
                    data-tooltip="When a Consumer is not running you can also change the offsets!"
                  >
                    <i className="fa fa-lightbulb-o" aria-hidden="true"></i>
                    <b> TIP</b>
                  </a>
                </p>
                <p className="d-md-none d-lg-none d-xl-none">
                  <i className="fa fa-lightbulb-o"></i>
                  <b> TIP</b>
                  <br />
                  When a Consumer is not running you can also change the
                  offsets!
                </p>
              </div>
            </div>

            <div className="col-md-6 pt-5 col-12">
              <div className="pt-5">
                <StaticImage
                  className="img-fluid mx-auto d-block shadow"
                  src="../resources/images/tour/consumers.png"
                  placeholder="tracedSVG"
                  alt="consumer-lag-lensesio"
                />
              </div>
            </div>

            <div className="col-md-12 col-12 text-center">
              <a title="step9" className="btn" href="#step9">
                <i className="fa fa-angle-down f-40" aria-hidden="true"></i>
              </a>
            </div>
          </div>
        </div>
      </section>

      <section
        className="p-5 mobile-padding-reset mobile-no-overflow"
        id="step9"
      >
        <div className="container-1 py-5 mobile-padding-reset">
          <div className="d-flex flex-wrap py-5 mobile-padding-reset">
            <div className="col-md-6 pt-3 col-12">
              <p className="box-title-number  mobile-margin-top">09.</p>
              <h2 className="pt-2">Monitor real-time flows</h2>

              <div className="pr-3 mobile-padding-reset mobile-margin-top">
                <div className="hero-description-dark pr-5 pb-0 mobile-padding-reset">
                  Access the <span className="code-text"> Topology </span>
                  to view a <code>realtime map</code>
                  of how{' '}
                  <a href="https://docs.lenses.io/4.3/tools/clients/external-apps/">
                    applications
                  </a>{' '}
                  ,<a href="/kafka-connectors/">connectors</a> and{' '}
                  <a href="/product/sql/">streaming SQL</a> interacts with Kafka
                  topics.
                  <br />
                  <br />
                  <small>
                    Click on a node to see the details, metrics and health
                    statuses.
                  </small>
                  <br />
                  <br />
                </div>
                <p className=" d-none d-md-block">
                  <a
                    href="#!"
                    className="tooltip-right p-text"
                    data-tooltip="You can add your applications, data pipelines and also microservices in the topology map."
                  >
                    <i className="fa fa-lightbulb-o" aria-hidden="true"></i>
                    <b> TIP</b>
                  </a>
                </p>
                <p className="d-md-none d-lg-none d-xl-none">
                  <i className="fa fa-lightbulb-o"></i>
                  <b> TIP</b>
                  <br />
                  You can add your applications, data pipelines and also
                  microservices in the topology map.
                </p>
              </div>
            </div>

            <div className="col-md-6 col-12 mt-5">
              <div>
                <StaticImage
                  className="img-fluid mx-auto d-block shadow-lg mt-5"
                  src="../resources/images/tour/topology.png"
                  placeholder="tracedSVG"
                  alt="topology"
                />
              </div>
            </div>

            <div className="col-md-12 col-12 text-center">
              <a title="step10" className="btn" href="#step10">
                <i className="fa fa-angle-down f-40" aria-hidden="true"></i>
              </a>
            </div>
          </div>
        </div>
      </section>

      <section
        className="bg-light p-5 mobile-padding-reset mobile-no-overflow"
        id="step10"
      >
        <div className="container-1 py-5 mobile-padding-reset">
          <div className="d-flex flex-wrap py-5 mobile-padding-reset">
            <div className="col-md-7 pt-3 col-12">
              <p className="box-title-number mobile-margin-top">10.</p>
              <h2 className="pt-2">Setup alerting</h2>
              <div className="pr-3 mobile-padding-reset">
                <div className="hero-description-dark pr-5 mobile-padding-reset">
                  Real-time monitoring and notifications for:
                  <br />
                  <br />
                  <p
                    className="hero-description-dark pt-0 mobile-padding-reset mobile-margin-top"
                    style={{ lineHeight: '1.5' }}
                  >
                    <i className="fa fa-check box-green-check pr-2" />
                    Infrastructure health
                    <br />
                    <i className="fa fa-check box-green-check pr-2" />
                    Producer SLAs
                    <br />
                    <i className="fa fa-check box-green-check pr-2" />
                    Consumer SLAs
                    <br />
                  </p>
                  <small>
                    <b>Note:</b> Learn more about{' '}
                    <a
                      href="https://help.lenses.io/guides/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      monitoring integrations
                    </a>
                  </small>
                </div>
              </div>
            </div>
            <div className="col-md-5 col-12 pt-5">
              <div>
                <StaticImage
                  className="img-fluid mx-auto d-block"
                  src="../resources/images/tour/alert.png"
                  placeholder="tracedSVG"
                  alt="alert"
                />
              </div>
            </div>
            <div className="col-md-12 col-12 text-center">
              <a title="step11" className="btn" href="#step11">
                <i className="fa fa-angle-down f-40" aria-hidden="true"></i>
              </a>
            </div>
          </div>
        </div>
      </section>

      <section
        className="p-5 mobile-padding-reset mobile-no-overflow"
        id="step11"
      >
        <div className="container-1 py-5 mobile-padding-reset">
          <div className="d-flex flex-wrap py-5 mobile-padding-reset align-items-center">
            <div className="col-md-7 col-12 pt-5">
              <h2 className="pt-2">Explore advanced options</h2>
              <div className="pr-3 mobile-padding-reset">
                <div
                  className="hero-description-dark pr-5 mobile-padding-reset"
                  style={{ lineHeight: '1.5' }}
                >
                  <i
                    className="fa fa-arrow-right box-green-arrow pr-2"
                    aria-hidden="true"
                  />
                  RBAC
                  <br />
                  <i
                    className="fa fa-arrow-right box-green-arrow pr-2"
                    aria-hidden="true"
                  />
                  Data centric security
                  <br />
                  <i
                    className="fa fa-arrow-right box-green-arrow pr-2"
                    aria-hidden="true"
                  />
                  Single Sign On authentication
                  <br />
                  <i
                    className="fa fa-arrow-right box-green-arrow pr-2"
                    aria-hidden="true"
                  />
                  <span className="code-text">HIPAA,GDPR,PCI </span>
                  data policies
                  <br />
                  <i
                    className="fa fa-arrow-right box-green-arrow pr-2"
                    aria-hidden="true"
                  />
                  Automate Flows with GitOps
                  <br />
                  <i
                    className="fa fa-arrow-right box-green-arrow pr-2"
                    aria-hidden="true"
                  />
                  Manage Kafka ACLs & Quotas
                  <br />
                  <i
                    className="fa fa-arrow-right box-green-arrow pr-2"
                    aria-hidden="true"
                  />
                  AWS MSK, Azure and other Cloud integrations
                </div>
                <p>
                  <a
                    href="https://docs.lenses.io"
                    className="primary-text"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Check the docs <i className="red-pointer-right" />
                  </a>
                </p>
              </div>
            </div>

            <div className="col-md-5 col-12 pt-5">
              <div className="pt-5">
                <StaticImage
                  className="img-fluid mx-auto d-block"
                  src="../resources/images/vectors/undraw_personal_settings_kihd.svg"
                  placeholder="tracedSVG"
                  alt="contact"
                />
              </div>
            </div>

            <div className="col-md-12 col-12 text-center">
              <a title="step12" className="btn" href="#step12">
                <i className="fa fa-angle-down f-40" aria-hidden="true"></i>
              </a>
            </div>
          </div>
        </div>
      </section>

      <AboveFooterSection
        text={'Want to try on your cluster?'}
        ctaText={'Talk with us'}
        ctaLink="/contact-us/"
      />

      <section className="mobile-padding-reset mobile-no-overflow" id="step12">
        <div className="container-1 py-2 mobile-padding-reset">
          <div className="d-flex flex-wrap py-5">
            <div className="col-md-12 col-12 text-center pt-5">
              {/*

                <img
                  className="img-fluid w-25 mx-auto d-block text-center"
                  src={undraw_development_ouy3}
                  alt="frequently asked questions-faq"
                />
                */}
              <h3 className="pt-2">Frequently Asked Questions</h3>
            </div>
          </div>
          <div className="d-flex flex-wrap">
            <div className="col-md-8 col-12 offset-md-2">
              {/* <!-- FAQ-1 --> */}
              <div className="card-group mb-3">
                <div className="card">
                  <div className="card-header">
                    <div className="card-title mb-0">
                      <a
                        data-toggle="collapse"
                        href="#faq1"
                        className="faq-link"
                      >
                        Can I connect Lenses to my Kafka Cluster?
                      </a>
                    </div>
                  </div>
                  <div id="faq1" className="collapse">
                    <div className="card-body">
                      <p>Yes.</p>
                      Lenses Box is optimized for development and includes a
                      single broker Kafka environment. However, you can evaluate
                      Lenses against your existing setup. Lenses works with all
                      popular Kafka distributions and clouds.{' '}
                      <a href="/contact-us/?s=apache-kafka-docker">
                        Contact our team to help you
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              {/* <!-- FAQ-2 --> */}
              <div className="card-group mb-3">
                <div className="card">
                  <div className="card-header">
                    <div className="card-title mb-0">
                      <a
                        data-toggle="collapse"
                        href="#faq2"
                        className="faq-link"
                      >
                        Is Lenses Box Free?
                      </a>
                    </div>
                  </div>
                  <div id="faq2" className="collapse">
                    <div className="card-body">
                      <p>Lenses Box is Free for ALL and forever.</p>
                      <p>
                        In order to use the Docker of Lenses Box you require a
                        free access key. You may need to refresh your key from
                        time to time for security reasons.
                      </p>
                      <p>
                        You will need a paid subscription to connect Lenses to
                        your own cluster or production.
                      </p>
                      <a href="/product/pricing/">See pricing</a>
                    </div>
                  </div>
                </div>
              </div>

              {/* <!-- FAQ-3 --> */}
              <div className="card-group mb-3">
                <div className="card">
                  <div className="card-header">
                    <div className="card-title mb-0">
                      <a
                        data-toggle="collapse"
                        href="#faq3"
                        className="faq-link"
                      >
                        Can I use Lenses Box in Production?
                      </a>
                    </div>
                  </div>
                  <div id="faq3" className="collapse">
                    <div className="card-body">
                      <p>It&apos;s not recommended. </p>
                      <p>
                        Lenses Box is a single-node setup. For production
                        environments it is recommended to have a multi-node
                        setup for scalability and fail-over.
                      </p>
                      <p>
                        For production or multi-node setups of your Kafka
                        cluster{' '}
                        <a href="/contact-us/?s=apache-kafka-docker">
                          contact our team
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              {/* <!-- FAQ-5 --> */}
              <div className="card-group mb-3">
                <div className="card">
                  <div className="card-header">
                    <div className="card-title mb-0">
                      <a
                        data-toggle="collapse"
                        href="#faq5"
                        className="faq-link"
                      >
                        Is Lenses compatible with Kafka cloud managed services?
                      </a>
                    </div>
                  </div>
                  <div id="faq5" className="collapse">
                    <div className="card-body">
                      <p>
                        Yes. Lenses works with AWS MSK, Azure HDInside, Aiven,
                        Confluent Cloud and other managed cloud services.
                      </p>
                      <p>
                        Lenses also provides deployment templates tailored for
                        each Cloud provider.
                      </p>
                      <p>
                        <a href="/product/deployment/">
                          Check Deployment Options
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              {/* <!-- FAQ-8 --> */}
              <div className="card-group mb-3">
                <div className="card">
                  <div className="card-header">
                    <div className="card-title mb-0">
                      <a
                        data-toggle="collapse"
                        href="#faq8"
                        className="faq-link"
                      >
                        What can I use Lenses for?
                      </a>
                    </div>
                  </div>
                  <div id="faq8" className="collapse">
                    <div className="card-body">
                      <p>
                        You can use Lenses to{' '}
                        <a href="/usecases/kafka-monitoring/">Monitor</a>,{' '}
                        <a href="/usecases/apache-kafka-data-observability/">
                          Explore
                        </a>
                        , <a href="/usecases/kafka-streams/">Build</a> and{' '}
                        <a href="/usecases/apache-kafka-security/">Secure</a>{' '}
                        your data platform.
                      </p>
                      <a href="/customers/">
                        Check how our customers use Lenses →
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="d-flex flex-wrap mt-5">
            <div className="col-md-12 text-center col-12">
              Do you have more questions?
              <br />
              <a
                className="primary-text"
                href="/contact-us/?s=apache-kafka-docker"
                id="box-faq-contactus"
              >
                Let us help <i className="red-pointer-right"></i>
              </a>
            </div>
          </div>
        </div>
      </section>
      <Spacing />
    </Layout>
  )
}
export default box
